<template>
  <div class="card-link">
    <span> {{ link_store }}</span>
  </div>
  <q-list style="min-width: 150px">
    <div v-for="(item, i) in me_store_params" :key="i">
      <q-item v-if="item.value" :key="i" target="_blank" :href="item.value">
        <q-item-section avatar>
          <q-avatar>
            <q-icon :name="$t(`card_store_menu.social.${item.icon}`)" />
          </q-avatar>
        </q-item-section>
        <q-item-section class="text-uppercase">
          {{ item.description }}
        </q-item-section>
      </q-item>
    </div>
    <q-separator />
    <q-item @click="copyLinkStore" tag="a" v-ripple clickable target="_blank">
      <q-item-section no-wrap>
        Copiar link
      </q-item-section>
      <q-item-section avatar no-wrap>
        <q-icon name="content_copy" />
      </q-item-section>
    </q-item>
    <q-item tag="a" v-ripple clickable target="_blank" v-if="link_store" :href="link_store"
      active-class="menu-item-active">
      <q-item-section>
        {{ $t("card_store_menu.open_on_new_window") }}
      </q-item-section>
      <q-item-section avatar>
        <q-icon name="open_in_new" />
      </q-item-section>
    </q-item>
    <q-item v-if="show_qrcode" @click="qrcodeMyStore" tag="a" v-ripple clickable target="_blank"
      active-class="menu-item-active">
      <q-item-section>
        {{ $t("card_store_menu.qrcode_my_store") }}
      </q-item-section>
      <q-item-section avatar>
        <q-icon name="qr_code" />
      </q-item-section>
    </q-item>
  </q-list>
  <q-item-section class="q-pa-md">
    <q-item-label class="q-mb-lg">
      {{ $t("card_store_menu.share") }}
    </q-item-label>
    <bar-share title="" description="" :link="link_store" :hideCopyLink="true" />
  </q-item-section>
</template>


<script setup>
import { defineProps } from 'vue'
import { copyToClipboard, useQuasar } from 'quasar';
import { notifyError, notifyInfo } from "@/shared/helpers/notify";
import BarShare from "@/modules/main/components/barShare";
import QrcodeMyStore from "./qrcode-my-store.vue";

const props = defineProps({
  link_store: {
    required: true,
  },
  is_cluster: {
    type: Boolean,
    default: false
  },
  show_qrcode: {
    type: Boolean
  },
  me_store_params: {
    type: Array,
    required: true,
  },
});

const $q = useQuasar();

const qrcodeMyStore = () => {
  $q.dialog({
    component: QrcodeMyStore,
    componentProps: {
      linkStore: props.link_store
    }
  })
};

const copyLinkStore = () => {
  copyToClipboard(props.link_store)
    .then(() => {
      notifyInfo("global.copied.success");
    })
    .catch(() => {
      notifyError("global.copied.error");
    });
}


</script>

<style lang="scss" scoped>
.card-link {
  padding: 10px;
  display: flex;
  word-break: break-all;
  flex-wrap: nowrap;
  background: #2a2a2a;
  color: #9e9e9e;
  overflow: auto;
  max-width: 287.5px;

  span {
    min-width: 280px;
  }
}
</style>