<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <div class="dialog-container">
      <q-card class="q-dialog-plugin">
        <q-btn flat @click="onDialogCancel" class="dialog-close">
          <q-icon name="close" />
        </q-btn>
        <div class="avatar-container">
          <avatar class="q-mb-sm avatar" />
        </div>
        <q-card-section class="section">
          <h4 class="text-center q-my-none text-h6">QRCode</h4>
          <p class="text-center">
            <small>
              Este QRCode direciona para o link principal da sua loja
            </small>
          </p>
          <qrcode-vue class="qrcode" :value="qrcodeConfig.value" :size="qrcodeConfig.size"
            :level="qrcodeConfig.level" />
          <div class="buttons row q-gutter-xs justify-center">
            <q-btn color="black" :loading="loading" class="text-normal row justify-center align-center"
              @click="downloadQrCode">
              <q-icon name="download" size="1rem" />
              <span class="q-pl-xs">Baixar QRCode</span>
            </q-btn>
          </div>
          <hr class="q-my-lg">
          <p class="text-center q-mt-none text-subtitle2 info">
            <small>
              Seus contatos podem escanear o código QR com a câmera do
              dispositivo para acessar sua loja
            </small>
          </p>
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>
<script setup>
import { defineEmits, defineProps, ref } from 'vue';
import { useDialogPluginComponent } from 'quasar'
import Avatar from "@/modules/main/components/avatar";
import QrcodeVue from 'qrcode.vue'

const props = defineProps({
  linkStore: {
    type: String,
    required: true
  }
})

defineEmits([
  ...useDialogPluginComponent.emits
])

const qrcodeConfig = {
  value: props.linkStore,
  level: 'L',
  size: 140
}

const loading = ref(false);

const { dialogRef, onDialogHide, onDialogCancel } = useDialogPluginComponent()

const downloadQrCode = () => {
  loading.value = true;

  const qrcode = document.querySelector('.qrcode');
  const imageUrl = qrcode.toDataURL('image/png'); // Converte o canvas para URL de dados
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = 'qrcode-my-store.jpg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    loading.value = false;
  }, 1000)
}

</script>
<style scoped>
.dialog-container {
  position: relative;
  padding-top: 40px;
}

.avatar-container {
  display: flex;
  justify-content: center;
  margin-top: -80px;
}

.floating-avatar {
  border: 4px solid white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.q-dialog-plugin {
  overflow: visible;
  padding-top: 40px;
  width: fit-content;
}

.avatar {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 90px;
  height: 90px;
}

.section h4 {
  margin-top: 0.5rem;
}

.qrcode {
  display: flex;
  margin: 30px auto;
}

.info {
  line-height: 1.2;
  color: #2d3436;
}

.text-normal {
  text-transform: initial;
}

.dialog-close {
  position: absolute;
  right: 0px;
  top: 0.6rem
}

@media (min-width: 599.99px) {
  .section {
    padding: 0px 32px 20px 32px;
  }

  .q-dialog-plugin {
    width: 400px;
  }
}
</style>
